import request from '@/utils/request'
/**
 * 公众端-分页查询审核通过的公示
 */
export const publicityApproved = (data) => {
  return request({
    url: '/credit/enterprise/publicity/approved',
    method: 'get',
    data
  })
}
/**
 * 公众端-创建信用公示异议
 */
export const createBiz = (data) => {
  return request({
    url: '/credit/enterprise/objection/createBiz',
    method: 'post',
    data
  })
}
/**
 * 获取免登录预告项目或集中式公寓项目列表
 */
export const searchItem = (data) => {
  return request({
    url: '/free/searchItem',
    method: 'POST',
    data
  })
}

/**
 * 获取免登录预告项目或集中式公寓项目列表
 */
export const newHouseList = (data) => {
  return request({
    url: '/free/houseFreeList',
    method: 'POST',
    data
  })
}

/**
 * 获取房源列表
 */
export const getHouseById = (data) => {
  return request({
    url: '/free/GetHouseById',
    params: data
  })
}

/**
 * 获取求租列表
 */
export const searchRentInfo = (data) => {
  return request({
    url: '/free/searchRentInfo',
    method: 'POST',
    data
  })
}

/**
 * 公示公告、行业动态列表
 */
export const getNotice = (data) => {
  return request({
    url: '/free/notice',
    method: 'POST',
    data
  })
}

/**
 * 获取企业信用红黑榜
 */
export const creditList = (data) => {
  return request({
    url: '/free/CreditList',
    method: 'POST',
    data
  })
}

/**
 * 登录接口
 */
export const rentLogin = (data) => {
  return request({
    url: '/sys/rentLogin',
    method: 'POST',
    data
  })
}

/**
 * 房源核验
 */
export const checkFYHY = (data) => {
  return request({
    url: '/free/FYYZbyFyhym',
    method: 'POST',
    data
  })
}

/**
 * 拨打虚拟电话
 * @param userId 用户ID
 * @returns {*}
 */
export const getVirtualPhoneApi = (userId) => {
  return request({
    url: '/free/virtualPhone',
    method: 'POST',
    timeout: 5 * 1000,
    data: {
      userId,
      mode: 'ax' // 虚拟拨号模型axb,ax
    }
  })
}

/**
 * 获取文章详情
 */
export const getArticleInfoApi = (params) => {
  return request({
    url: '/free/noticeDatail',
    method: 'GET',
    params
  })
}
