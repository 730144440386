<template>
  <div style='height: 100%'>
    <nesi-user-common :title='showResult?`验真查询`:`查验结果`'>
      <template #content v-if='!showResult'>
        <el-form
          ref='vform'
          :model='vformData'
          :rules='rules'
          label-width='120px'
          size='default'
          class='vform-c'
        >
          <el-form-item label='房源核验码' prop='verifyCode'>
            <el-input
              v-model='vformData.verifyCode'
              clearable
              placeholder='请输入房源核验码'
            />
          </el-form-item>
          <el-form-item label='图片验证码' prop='captcha'>
            <el-row>
              <el-col :span='16'>
                <el-input
                  v-model='vformData.captcha'
                  clearable
                  placeholder='不区分大小写'
                />
              </el-col>
              <el-col :span='7'>
                <nesi-image-code ref='checkImgRef' v-model='vformData.checkKey' />
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='submitForm()'>查询</el-button>
          </el-form-item>
        </el-form>

      </template>
      <template v-else #content>
        <el-descriptions
          style='margin-top: 32px'
          class='margin-top'
          title='核验结果'
          :column='2'
          size='large'
          border
        >
          <template #extra>
            <el-button type='primary' @click='backClick'>返回</el-button>

          </template>
          <el-descriptions-item label='房源核验码' :span='2'>
            {{ houseInfo.gov_house_number }}
          </el-descriptions-item>
          <el-descriptions-item label='小区名称'>
            {{ houseInfo.xmmc }}
          </el-descriptions-item>
          <el-descriptions-item label='楼号'>
            {{ houseInfo.building_no }}
          </el-descriptions-item>
          <el-descriptions-item label='户型居室'>
            {{ houseInfo.fwhx ? houseInfo.fwhx : '----' }}
          </el-descriptions-item>
          <el-descriptions-item label='房屋朝向'>
            {{ houseInfo.toward_name }}
          </el-descriptions-item>
          <el-descriptions-item label='建筑面积'>
            {{ houseInfo.space }}
          </el-descriptions-item>
          <el-descriptions-item label='是否已出租'>
            <span v-if='houseInfo.rent_status===2'>已发合同</span>
            <span v-if='houseInfo.rent_status===1'>已出租</span>
            <span v-if='houseInfo.rent_status===0'>未出租</span>
          </el-descriptions-item>
          <el-descriptions-item label='核验时间'>
            {{ houseInfo.created_at }}
          </el-descriptions-item>
          <el-descriptions-item label='核验机构' :span='2'>
            {{ houseInfo.qymc ? houseInfo.qymc : '----' }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </nesi-user-common>
  </div>
</template>
<script setup>
import { defineComponent, ref, reactive } from 'vue'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import NesiImageCode from '@/components/NesiImageCode/index.vue'
import { checkFYHY } from '@/api/home'
import { ElMessage } from 'element-plus'

defineComponent({
  NesiUserCommon,
  NesiImageCode
})
const vform = ref()
const showResult = ref(false)

const houseInfo = ref({})
const checkImgRef = ref()
const vformData = reactive({
  captcha: '',
  verifyCode: '',
  checkKey: ''
})

const rules = ref({
  verifyCode: [
    { required: true, message: '请输入房源核验码', trigger: 'blur' }
  ],
  captcha: [
    { required: true, message: '请输入右图中显示的验证码', trigger: 'blur' }
  ]
})
// 提交表单
const submitForm = () => {
  vform.value.validate(async (valid) => {
    if (valid) {
      const house = await checkFYHY(vformData)
      if (!house || !house.gov_house_number) {
        ElMessage.error('未找到房源数据') // 提示错误消息
        return
      }
      showResult.value = true
      houseInfo.value = house
    } else {
      return false
    }
  })
}
const backClick = () => {
  vformData.captcha = ''
  vformData.verifyCode = ''
  showResult.value = false
  houseInfo.value = {}
  checkImgRef.value.reset()
}

</script>
<style lang='scss'>
.vform-c {

  width: 400px;
  margin: 40px auto;

}
</style>
