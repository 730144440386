<template>
  <el-image
    :src='imageUrl'
    style='display: flex; height: 38px; cursor: pointer'
    @click='getImage'
  >

    <template #error>
      <div class='image-slot'>
        <el-icon>
          <icon-picture />
        </el-icon>
      </div>
    </template>
  </el-image>
</template>

<script>
import { randomImage } from '@/api/login'
// 短信验证码
export default {
  name: 'index',
  emits: ['update:modelValue'],
  data() {
    return {
      imageUrl: '',
      chckKey: ''
    }
  },
  mounted() {
    this.getImage()
  },
  methods: {
    async getImage() {
      this.chckKey = new Date().getTime()
      const resp = await randomImage({ key: this.chckKey })
      this.imageUrl = resp
      this.$emit('update:modelValue', this.chckKey)
    },
    reset() {
      this.getImage()
    }
  }
}
</script>

<style scoped>

</style>
